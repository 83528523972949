import React from "react"
import { Link } from "gatsby"
// Import css design
import "./../../styles/integrations.css"

const MostPopularIntegrations = ({ data }) => {
  const integrations = [
    {
      name: "Slack",
      handlename: "slack",
      description:
        "Slack messages are a great way to inform the entire team of a downtime.",
      icon: "slack.svg",
      supported: true,
      popular: true,
    },
    {
      name: "Webhooks",
      handlename: "webhooks",
      description:
        "For advanced alerting you can setup webhooks to your own system or app.",
      icon: "webhooks.svg",
      supported: true,
      popular: true,
    },
    {
      name: "Telegram",
      handlename: "telegram",
      description: "Telegram messages are a great way how to stay alerted.",
      icon: "integration-telegram.svg",
      supported: true,
      popular: true,
    },
    {
      name: "Discord",
      handlename: "discord",
      description:
        "Get important monitor status updates in your Discord messages.",
      icon: "integration-discord.svg",
      supported: true,
      popular: true,
    },
    {
      name: "PagerDuty",
      handlename: "pagerduty",
      description:
        "Send up & down events and auto-resolve your incidents in PagerDuty.",
      icon: "pagerduty-icon.svg", //'pagerduty.png',
      supported: true,
      popular: true,
    },
    {
      name: "Opsgenie",
      handlename: "opsgenie",
      description: "Receive Odown alerts in your OpsGenie account",
      icon: "opsgenie.png",
      supported: true,
      popular: false,
    },
    {
      name: "Twilio",
      handlename: "twilio",
      description:
        "Connect your account and send to your status page subscribers the SMS notifications ",
      icon: "integration-twilio.svg",
      supported: true,
      popular: false,
    },
    {
      name: "Vonage(Nexmo)",
      handlename: "vonage",
      description:
        "Connect your account and send to your status page subscribers the SMS notifications ",
      icon: "integration-vonage.svg",
      supported: true,
      popular: false,
    },
    {
      name: "Zapier",
      handlename: "zapier",
      description: "Integrate your Zapier account to get alerted right away.",
      icon: "integration-zapier.svg",
      supported: false,
      popular: false,
    },
    {
      name: "Pushbullet",
      handlename: "pushbullet",
      description:
        "Get instant alerts via mobile push notifications in Pushbullet Android app.",
      icon: "integration-pushbullet.svg",
      supported: false,
      popular: false,
    },
    {
      name: "Microsoft Team",
      handlename: "microsoftteam",
      description:
        "Get notifications inside your MS Teams app to alert everyone in the group.",
      icon: "integration-microsoft-teams.svg",
      supported: false,
      popular: false,
    },
    {
      name: "Google Chat",
      handlename: "googlechat",
      description:
        "Get notifications into the Google Chat. Just copy the room URL to Odown.",
      icon: "integration-hangouts.svg",
      supported: false,
      popular: false,
    },
    {
      name: "Pushover",
      handlename: "pushover",
      description:
        "Get instant alerts via mobile push notifications in Pushover app.",
      icon: "integration-pushover.svg",
      supported: false,
      popular: false,
    },
    {
      name: "Twitter",
      handlename: "twitter",
      description:
        "Let us send you a private message on Twitter about your outages.",
      icon: "integration-twitter.svg",
      supported: false,
      popular: false,
    },
  ]

  return (
    <section
      id="integrations"
      className="u-features integrations-list bref-items"
    >
      <div className="container">
        <h2>
          We support alerts on{" "}
          {integrations.filter(x => x.supported === true).length} different
          platforms, including...
        </h2>
        <div className="available-integrations">
          {integrations
            .filter(x => x.popular === true)
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={`intg-item_wrapper ${
                    item.supported ? "" : "coming-soon"
                  }`}
                >
                  <label className="intg-item">
                    <div className="intg--icon">
                      <img src={`/${item.icon}`} alt={item.handlename} />
                    </div>
                    <div className="intg--name">{item.name}</div>
                  </label>
                </div>
              )
            })}
        </div>
        <Link className="link-to-full-list" to={`/integrations`}>
          See the full list of supported integrations
        </Link>
      </div>
    </section>
  )
}

export default MostPopularIntegrations
